import Vue from 'vue'
// import '@/plugins/axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { api } from '@/plugins/axios'
import { i18n } from '@/plugins/i18n'
// import './plugins/mockjs'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router/index'
import store from '@/store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import Util from './utils/util.js'
import * as enumMap from './utils/enumMap.js'
import * as loanMap from './utils/loanMap.js'
import './utils/dragModal'
import { hasPermission } from '@/utils/index.js';

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCBBYeCOSykMFXr1orFQ9og_myUKwEVgfw', //AIzaSyA7q7OvWsNp_qlSU9mA8PeheW2PVtFaGyI
		libraries: 'places,drawing',
		v: 'weekly',
		language: 'en'
	}
})

Vue.use(Viewer)
Viewer.setDefaults({
	Options: {
		inline: true,
		button: true,
		navbar: true,
		title: true,
		toolbar: true,
		tooltip: true,
		movable: true,
		zoomable: true,
		rotatable: true,
		scalable: true,
		transition: true,
		fullscreen: true,
		keyboard: true,
		url: 'data-source'
	}
})
import {
	Tree,
	Statistic,
	Switch,
	Upload,
	Notification,
	Popconfirm,
	Collapse,
	List,
	Comment,
	Timeline,
	Radio,
	DatePicker,
	TimePicker,
	Checkbox,
	Icon,
	Button,
	Layout,
	Menu,
	Dropdown,
	Tag,
	Affix,
	Avatar,
	Drawer,
	Divider,
	Tooltip,
	Breadcrumb,
	Card,
	Table,
	Pagination,
	Form,
	Input,
	InputNumber,
	Tabs,
	Col,
	Row,
	Alert,
	Modal,
	Select,
	Spin,
	Message,
	Transfer,
	FormModel,
	Steps,
	Descriptions,
	Empty,
	Badge,
	Popover,
	AutoComplete,
	Space
} from 'ant-design-vue'
const antdComponents = [
	Tree,
	Statistic,
	Statistic.Countdown,
	Switch,
	Upload,
	Popconfirm,
	Collapse,
	Collapse.Panel,
	List,
	List.Item,
	Comment,
	Timeline,
	Timeline.Item,
	Radio,
	Radio.Group,
	DatePicker,
	DatePicker.RangePicker,
	TimePicker,
	Checkbox,
	Checkbox.Group,
	Icon,
	Button,
	Layout,
	Layout.Sider,
	Layout.Content,
	Layout.Header,
	Layout.Footer,
	Menu,
	Menu.Item,
	Menu.SubMenu,
	Dropdown,
	Tag,
	Affix,
	Avatar,
	Drawer,
	Divider,
	Tooltip,
	Breadcrumb,
	Breadcrumb.Item,
	Card,
	Card.Grid,
	Card.Meta,
	Table,
	Pagination,
	Form,
	Form.Item,
	Input,
	Input.Group,
	Input.TextArea,
	Input.Search,
	InputNumber,
	Tabs,
	Tabs.TabPane,
	Col,
	Row,
	Alert,
	Modal,
	Select,
	Select.Option,
	Spin,
	Transfer,
	FormModel,
	Steps,
	Descriptions,
	Empty,
	Badge,
	Popover,
	AutoComplete,
	Space
]
antdComponents.forEach((c) => {
	Vue.use(c)
})
Vue.prototype.$form = Form
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$error = Modal.error
Vue.prototype.$success = Modal.success
Vue.prototype.$message = Message
Vue.prototype.$notification = Notification
Vue.prototype.$api = api
Vue.prototype.$Util = Util
Vue.prototype.$enumMap = enumMap
Vue.prototype.$loanMap = loanMap
Vue.prototype.$hasPermission = hasPermission
const requireComponent = require.context(
	// 其组件目录的相对路径
	'./components',
	// 是否查询其子目录
	true,
	// 匹配基础组件文件名的正则表达式
	/[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
	// 获取组件配置
	const componentConfig = requireComponent(fileName)

	// 获取组件的 PascalCase 命名
	const componentName = upperFirst(
		camelCase(
			// 获取和目录深度无关的文件名
			fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, '')
		)
	)

	// 全局注册组件
	Vue.component(
		componentName,
		// 如果这个组件选项是通过 `export default` 导出的，
		// 那么就会优先使用 `.default`，
		// 否则回退到使用模块的根。
		componentConfig.default || componentConfig
	)
})

Vue.directive('drag', {
	// 当被绑定的元素插入到 DOM 中时……
	inserted: function (el) {
		let odiv = el // 获取当前元素
		odiv.onmousedown = (e) => {
			// 算出鼠标相对元素的位置
			let disX = e.clientX - odiv.offsetLeft
			let disY = e.clientY - odiv.offsetTop

			document.onmousemove = (e) => {
				// 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
				let left = e.clientX - disX
				let top = e.clientY - disY
				// 绑定元素位置到positionX和positionY上面
				// this.positionX = top;
				// this.positionY = left;

				if (left < 0) return
				if (top < 0) return
				// 移动当前元素
				odiv.style.left = left + 'px'
				odiv.style.top = top + 'px'
			}
			document.onmouseup = (e) => {
				document.onmousemove = null
				document.onmouseup = null
			}
		}
	}
})
Vue.directive('has', {
	inserted: function (el, binding, vue) {
		if (vue.context.permission.isAdmin) {
			return
		}
		let visibilityMap = vue.context.permission.visibility
		let permissionKey = vue.context.permissionValue
		let elementKey = binding.value

		let isVisibility = visibilityMap[permissionKey][elementKey]
		if (!isVisibility) {
			el.parentNode.removeChild(el)
		}
	}
})
Vue.config.productionTip = false
new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')
