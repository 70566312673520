import { _axios } from '@/plugins/axios'

// 申请资料填报
export const apiPostApplyInfo = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/applyInfo`,
		method: method,
		data: dataJson
	})
}

// 补充放款后logbook信息
export const apiDisbursedLogbook = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/disbursed/supple/logbook`,
		method: method,
		data: dataJson
	})
}

export const apiChangeDueDay = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/changeDueDay`,
		method: method,
		data: dataJson
	})
}

// 改变利息
export const apiChangeInterest = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/changeInterest`,
		method: method,
		data: dataJson
	})
}

// 资料详情
export const apiApplyInfo = (id, notify, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/${id}`,
		method: method,
		data: {},
		notify: notify === false ? false : true
	})
}

// 放款资料
export const apiPostDisburseInfo = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/disburseInfo`,
		method: method,
		data: dataJson
	})
}

// gps状态
export const apiGetGpsStatus = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/gps/status`,
		method: method,
		data: dataJson
	})
}

// 申请资料列表
export const apiGetApplyList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/apply/info`,
		method: method,
		data: dataJson
	})
}

// 申请资料审核不通过
export const apiGetApplyFailList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/apply/fail`,
		method: method,
		data: dataJson
	})
}

// 申请客户
export const apiGetDisburseApply = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/customer/apply`,
		method: method,
		data: dataJson
	})
}

// 官网广告客户
export const apiClientLeave = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/client/leave/page`,
		method: method,
		data: dataJson
	})
}

// 放款资料列表
export const apiGetDisburseInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/disburse/info`,
		method: method,
		data: dataJson
	})
}

// 放款资料审核不通过
export const apiGetDisburseFailInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `car/loan/apply/list/disburse/fail`,
		method: method,
		data: dataJson
	})
}

// 放款成功客户
export const apiGetDisburse = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/list`,
		method: method,
		data: dataJson
	})
}

// 放款成功客户
export const apiFinanceData = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/finance/data`,
		method: method,
		data: dataJson
	})
}

// 放款成功客户
export const apiRepayListData = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/repayList/data`,
		method: method,
		data: dataJson
	})
}

// 录入还款案件列表
export const apiGetFinanceList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/finance/list`,
		method: method,
		data: dataJson
	})
}

// 获得流水状态
export const apiGetFileStatus = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/other/getFileStatus?${dataJson}`,
		method: method
	})
}

// 发起流水审核
export const apiSendLoanFileCredit = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/other/sendLoanFileCredit`,
		method: method,
		data: dataJson
	})
}

// 获得流水结果
export const apiLoanDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/flowUser/loanDetail`,
		method: method,
		data: dataJson
	})
}

// 待放款列表
export const apiLoanWaiting = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/disburse/waiting`,
		method: method,
		data: dataJson
	})
}

// 待放款列表
export const apiDisburseLoanWaiting = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/disburse/waiting/data`,
		method: method,
		data: dataJson
	})
}

//  获取征信报告
export const apiGetReport = (id, method = 'get') => {
	return _axios({
		url: `/car/credit/report/${id}`,
		method: method,
		data: {}
	})
}

export const apiGetReportFile = (id, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/data/obtain/reportFile?nationalld=${id}`,
		method: method,
		data: {},
		responseType: 'arraybuffer'
	})
}

export const apiTransunionReportFile = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/data/obtain/transunion/reportFile`,
		method: method,
		data: dataJson,
		responseType: 'arraybuffer'
	})
}

// 获得列表
export const apiRepayList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/repayList`,
		method: method,
		data: dataJson
	})
}

// 获得还款审核列表
export const apiRepayCheck = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/repayCheck`,
		method: method,
		data: dataJson
	})
}

// 根据姓名搜索loan
export const apiFindLoanByName = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/searchKeyword`,
		method: method,
		data: dataJson
	})
}

// 根据姓名搜索loan
export const apiSearchClient = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/upfront/searchClient`,
		method: method,
		data: dataJson
	})
}

// 录入上传凭证
export const apiLoanTransaction = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction`,
		method: method,
		data: dataJson
	})
}

// mpesa离线还款upload
export const apiUploadLoanTransaction = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/mpesaOffline/upload`,
		method: method,
		data: dataJson
	})
}

// mpesa离线还款upload
export const apiMpesaOfflineDelete = (dataJson, method = 'delete') => {
	return _axios({
		url: `/car/loanTransaction/offline`,
		method: method,
		data: dataJson
	})
}

// 录mpesa离线还款check
export const apiCheckLoanTransaction = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/mpesaOffline/check`,
		method: method,
		data: dataJson
	})
}

// 还款金额试算
export const apiDiscountAmount = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/discountAmount`,
		method: method,
		data: dataJson
	})
}

// 还款金额试算
export const apiRecommendAmount = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/recommendAmount`,
		method: method,
		data: dataJson
	})
}

// 根据贷款id获得最大平账金额
export const apiGetMaxExtraAmount = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/getMaxExtraAmount`,
		method: method,
		data: dataJson
	})
}

// 获取估值报告数据
export const apiGetValuation = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/data/obtain/valuation`,
		method: method,
		data: dataJson
	})
}

// 获取NTSA数据
export const apiGetNTSA = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/data/obtain/NTSA`,
		method: method,
		data: dataJson
	})
}

// 获取PIN数据
export const apiGetPIN = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/data/obtain/PIN`,
		method: method,
		data: dataJson
	})
}

// 保存贷款申请信息（确认节点）
export const apiApplyConfirmLoanInfo = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/applyConfirmLoanInfo`,
		method: method,
		data: dataJson
	})
}

// 保存保险信息（确认节点）
export const applyConfirmInsuranceInfo = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/applyConfirmInsuranceInfo`,
		method: method,
		data: dataJson
	})
}

// 保险试算
export const insTryCalculate = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/applyTryCalculate/insurance`,
		method: method,
		data: dataJson
	})
}

// 保存贷款申请信息（确认节点）
export const apiHasFillRegistrationNumber = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/disburseInfo/hasFillRegistrationNumber?loanId=${dataJson}`,
		method: method
	})
}

// 设置离线付款为贷前费用
export const apiUpfrontSet = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/upfront/set`,
		method: method,
		data: dataJson
	})
}

// 设置离线付款为贷前费用
export const apiUpfrontSearchPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/upfront/searchPage`,
		method: method,
		data: dataJson
	})
}

// 设置离线付款为贷前费用
export const apiUpfrontMatch = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/upfront/match`,
		method: method,
		data: dataJson
	})
}

// 预览还款
export const apiPreview = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/preview`,
		method: method,
		data: dataJson
	})
}

// 还款撤回
export const apiRollback = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/rollback`,
		method: method,
		data: dataJson
	})
}

// STATEMENT
export const apiStatement = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/statement?loanId=${dataJson}`,
		method: method
	})
}

// 超额偿还列表
export const apiOverpaidPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/finance/overpaid/page`,
		method: method,
		data: dataJson
	})
}

// 超超额偿还退还
export const apiOverpaidRefund = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/finance/overpaid/refund`,
		method: method,
		data: dataJson
	})
}

// 超额偿还还款
export const apiOverpaidRepay = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/finance/overpaid/repay`,
		method: method,
		data: dataJson
	})
}

// 超额偿还upload
export const apiOverpaidUpload = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/finance/overpaid/upload`,
		method: method,
		data: dataJson
	})
}

// 修正罚息
export const apiRegeneratePenalty = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/fee/input/regeneratePenalty`,
		method: method,
		data: dataJson
	})
}

// 修正费用
export const apiModifyFee = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/fee/input/modifyFee`,
		method: method,
		data: dataJson
	})
}

// 删除费用
export const apiDeleteFee = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/fee/input/deleteFee`,
		method: method,
		data: dataJson
	})
}

// 提前还清预览
export const apiRollSettlementPreview = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/rollSettlement/preview`,
		method: method,
		data: dataJson
	})
}

// 提前还清
export const apiEarlySettlement = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/earlySettlement`,
		method: method,
		data: dataJson
	})
}

// 提前还清计算
export const apiEstimateSettlement = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/estimateSettlement`,
		method: method,
		data: dataJson
	})
}

// 提前还清回滚
export const apiRollbackSettlement = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/rollbackSettlement`,
		method: method,
		data: dataJson
	})
}

// 贷款单试算
export const apiApplyTryCalculate = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/applyTryCalculate`,
		method: method,
		data: dataJson
	})
}

export const apiDisburseEarlyInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/early/info`,
		method: method,
		data: dataJson
	})
}

export const apiDisburseRepayInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/repay/loan`,
		method: method,
		data: dataJson
	})
}

export const apiPicValuation = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/disbursed/supple/picValuation`,
		method: method,
		data: dataJson
	})
}

export const deleteLeave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/client/leave/delete`,
		method: method,
		data: dataJson
	})
}

export const convertLeave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/client/leave/convert`,
		method: method,
		data: dataJson
	})
}

// 获取我的草稿
export const getDisburseInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/info/mine`,
		method: method,
		data: dataJson
	})
}

// 获取银行代码
export const getBankList= (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/getBankCodes`,
		method: method,
		data: dataJson
	})
}

// 新增或保存草稿
export const addOrUpdateDisburseDraft = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/info/mine/save`,
		method: method,
		data: dataJson
	})
}

// 提交我的草稿
export const submitDisburseDraft = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/info/mine/submit`,
		method: method,
		data: dataJson
	})
}

// 批量提交我的草稿
export const submitAllDisburseDrafts = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/info/mine/submit/batch`,
		method: method,
		data: dataJson
	})
}

// 删除草稿
export const deleteDisburseDraft = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/info/mine/delete`,
		method: method,
		data: dataJson
	})
}

// 获取网银记录
export const getDisburseRecord = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/record`,
		method: method,
		data: dataJson
	})
}

// 保存我的网银记录
export const addDisburseRecord = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/record/save`,
		method: method,
		data: dataJson
	})
}

// 删除我的网银记录
export const deleteDisburseRecord = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/record/delete`,
		method: method,
		data: dataJson
	})
}

// 获取审核放款记录（含线上线下）
export const getDisburseCheckRecord = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/record/check`,
		method: method,
		data: dataJson
	})
}

// 获取已放款金额
export const getDisbursedAmount = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/info/review/amount`,
		method: method,
		data: dataJson
	})
}

// 获取审核列表
export const getDisbursedReviewList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/info/review`,
		method: method,
		data: dataJson
	})
}

// 审核线上支付
export const reviewDisbursedRecords = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/disburse/info/review/review`,
		method: method,
		data: dataJson
	})
}

// 提前还清录入
export const apiSaveEarly = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/early/save`,
		method: method,
		data: dataJson
	})
}

// 提前还清预览
export const apiEarlyPreview = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/early/preview`,
		method: method,
		data: dataJson
	})
}

// 提前还清金额试算
export const apiEarlyEstimate = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/early/estimate`,
		method: method,
		data: dataJson
	})
}

// 提前还清还款删除
export const apiEarlyDelete = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/early/delete`,
		method: method,
		data: dataJson
	})
}

// 提前还清还款删除
export const apiEarlySubmit = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/early/submit`,
		method: method,
		data: dataJson
	})
}

// 
export const apiEarlyDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/early/detail`,
		method: method,
		data: dataJson
	})
}